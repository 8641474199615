import { useLoader, useFrame, Canvas } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { WebGLRenderer } from "three";

function ModelAnimation(props) {
  const model = useLoader(GLTFLoader, process.env.PUBLIC_URL + props.path);
  let mixer;
  if (model.animations.length) {
    mixer = new THREE.AnimationMixer(model.scene);
    model.animations.forEach((doc) => {
      let action = mixer.clipAction(doc);
      action.play();
    });
    var modelgltf = model.scene;
    // try {
    //   modelgltf.traverse((mod) => {
    //     if (mod.isMesh) {
    //       mod.material.color = new THREE.Color("rgb(22,208,255)");
    //       mod.attenuationColor = new THREE.Color("rgb(22,208,255)");
    //       mod.material.transparent = true;
    //       mod.sheenColor = new THREE.Color("rgb(22,208,255)");
    //       // mod.opacity = 0.7;
    //       mod.opacity = 1;
    //     }
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
  }
  useFrame((state, delta) => {
    mixer?.update(delta);
  });

  return (
    <primitive
      object={model.scene}
      scale={props.scale}
      position={props.position}
    />
  );
}
export default ModelAnimation;
