import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { TypeAnimation } from "react-type-animation";
import "swiper/css";
import "./../css/main.css";
import { BiCaretLeft } from "react-icons/bi";
import { BiCaretRight } from "react-icons/bi";
import "./../css/font.css";
import { useTranslation } from "react-i18next";
function Slider({ images }) {
  const swiperRef = useRef(null);
  const [showText, setShowText] = useState(true);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    setShowText(false);
    setTimeout(() => {
      setShowText(true);
    }, 500);
  }, [i18n.language]);
  return (
    <Swiper
      // spaceBetween={50}
      slidesPerView={1}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      loop={false}
      // pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      navigation
      onSlideChange={() => console.log("slide change")}
      className="main-index"
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <img
              className="w-100 image-show"
              src={image.imgURL}
              alt={image.imgAlt}
            />
          </SwiperSlide>
        );
      })}
      <div className="show-image-btn">
        {/*         <div
          className="image-btn"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <BiCaretLeft className="i-con-white" />
        </div>
        <div
          className="image-btn"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <BiCaretRight className="i-con-white" />
        </div> */}
      </div>
      <div className="name-web suk-bold">
        <div>
          {showText ? (
            <TypeAnimation
              sequence={[
                "",
                500,
                i18n.language === "en"
                  ? "MIN-ERGIE\n DEVELOPER"
                  : i18n.language === "de"
                  ? "Projekt\n MIN-ERGIE"
                  : "Développement\n Min-Ergie",
                500,
                i18n.language === "en"
                  ? "MIN-ERGIE\n DEVELOPMENT"
                  : i18n.language === "de"
                  ? "Projekt\n MIN-ERGIE"
                  : "Développement\n Min-Ergie",
                500,
              ]}
              style={{ whiteSpace: "pre-line" }}
              repeat={false}
            />
          ) : null}
        </div>
      </div>
    </Swiper>
  );
}

export default Slider;
