import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import { extend, useThree } from "@react-three/fiber";
import { useRef, useEffect, useState } from "react";
extend({ DragControls });
const randomData = (data, value) => {
  try {
    if (data.children.length > 0) {
      data.children.forEach((doc) => {
        randomData(doc, value);
      });
    } else {
      data.material.opacity = value;
    }
  } catch (e) {
    console.log(data, e);
  }
};
const Dragable = (props) => {
  const groupRef = useRef();
  const controlsRef = useRef();
  const [children, setChildren] = useState([]);
  const { camera, gl, scene } = useThree();
  const [first, setFirst] = useState(null);
  useEffect(() => {
    setChildren(groupRef.current.children);
  }, []);
  // useEffect(() => {
  //   if (first) {
  //     let allMesh = scene.children[2].children[0].children[0].children;
  //     let all = [[2], [6], [0, 7, 5], [7], [5], [4], [3], [1]];
  //     let showAll = [];
  //     if (props.click.length !== 0) {
  //       props.click.forEach((begin) => {
  //         all[begin].forEach((i) => {
  //           randomData(allMesh[i], 1);
  //           showAll.push(i);
  //         });
  //       });
  //       all.forEach((data) => {
  //         data.forEach((i) => {
  //           if (!showAll.includes(i)) {
  //             randomData(allMesh[i], 0.1);
  //             // allMesh[data].children.forEach((doc) => {
  //             //   doc.children.forEach((child) => {
  //             //     if (doc.children.length > 0) {
  //             //       child.children.forEach((child1) => {
  //             //         child1.children.forEach((child2) => {
  //             //           if (child2.children.length > 0) {
  //             //             child2.children.forEach((child3) => {
  //             //               if (child3.children.length > 0) {
  //             //                 child3.children.forEach((child4) => {
  //             //                   if (child4.children.length > 0) {
  //             //                     child4.children.forEach((child5) => {
  //             //                       if (child5.children.length > 0) {
  //             //                         child5.children.forEach((child6) => {
  //             //                           if (child6.children.length > 0) {
  //             //                             child6.children.forEach((child7) => {
  //             //                               if (child6.children.length > 0) {
  //             //                                 child7.children.forEach(
  //             //                                   (child8) => {
  //             //                                     child8.material.opacity = 0.1;
  //             //                                   }
  //             //                                 );
  //             //                               } else {
  //             //                                 child7.material.opacity = 0.1;
  //             //                               }
  //             //                             });
  //             //                           } else {
  //             //                             child6.material.opacity = 0.1;
  //             //                           }
  //             //                         });
  //             //                       } else {
  //             //                         child5.material.opacity = 0.1;
  //             //                       }
  //             //                     });
  //             //                   } else {
  //             //                     child4.material.opacity = 0.1;
  //             //                   }
  //             //                 });
  //             //               } else {
  //             //                 child3.material.opacity = 0.1;
  //             //               }
  //             //             });
  //             //           } else {
  //             //             child2.material.opacity = 0.1;
  //             //           }
  //             //         });
  //             //       });
  //             //     } else {
  //             //       child.material.opacity = 0.1;
  //             //     }
  //             //   });
  //             // });
  //           }
  //         });
  //       });
  //     } else {
  //       all.forEach((data) => {
  //         data.forEach((i) => {
  //           randomData(allMesh[i], 1);
  //         });
  //       });
  //     }
  //   } else {
  //     setFirst(true);
  //   }
  // }, [props.click]);
  useEffect(() => {
    controlsRef.current.addEventListener("hoveron", (e) => {
      // scene.orbitControls.enabled = false
      // let id = e.object.uuid;
      // e.object.parent.children.forEach(doc => {
      //   if (doc.uuid !== id) {
      //     doc.material.opacity = 0.1;
      //   }
      // })
      // e.object.material.opacity = 1;
      // console.log(e.object.parent);
    });
    controlsRef.current.addEventListener("hoveroff", (e) => {
      // console.log(e)
      // scene.orbitControls.enabled = true
      // e.object.material.opacity = 1;
      // e.object.parent.children.forEach(doc => {
      //   doc.material.opacity = 1;
      // })
    });
    controlsRef.current.addEventListener("dragstart", (e) => {
      // console.log(e.object)
      e.object.api?.mass.set(0);
      // e.object.material.opacity = 0.1
    });

    controlsRef.current.addEventListener("dragend", (e) =>
      e.object.api?.mass.set(1)
    );
    controlsRef.current.addEventListener("drag", (e) => {
      e.object.api?.position.copy(e.object.position);
      e.object.api?.velocity.set(0, 0, 0);
      e.object.position.set(0, 0, 0);
    });
  }, [children]);
  return (
    <group ref={groupRef}>
      <dragControls
        transformGroup={props.transformGroup}
        ref={controlsRef}
        args={[children, camera, gl.domElement]}
      />
      {props.children}
    </group>
  );
};

export default Dragable;
