import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Hamburger from "hamburger-react";
import Logo from "./../image/logo.webp";
import logoPhone from "./../image/logo-color-eco.webp";
import LogoColor from "./../image/logo-color-eco.webp";
import Facebook from "../image/footer/facebook.webp";
// import Instagram from "../image/footer/instagram.webp";
import Call from "./../image/call.webp";
import Mail from "../image/footer/mail.webp";
import { BiPhoneCall } from "react-icons/bi";
import FooterLogo from "../image/footer/footer-logo.webp";
import "./../css/layout.css";
import "./../css/footer.css";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import FacebookMsg from "./FacebookMsg";

function Layout() {
  const { i18n, t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000); // 1 hour
  const cookies = new Cookies();
  const changeLanguage = (lng) => {
    cookies.set("language", lng, {
      path: "/",
      expires: expirationDate,
    });
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    window.addEventListener("touchmove", ontouchmove);
    function ontouchmove(e) {
      if (e.cancelable) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }
  }, []);
  // if (currentPath === "/about") {
  //   return (
  //     <div>
  //       <Outlet />
  //       <div className="footer">
  //         <div className="title suk-bold">{t("contact_us")}</div>
  //         <div className="group-footer">
  //           <div
  //             className="address suk-semibold"
  //             dangerouslySetInnerHTML={{ __html: t("address") }}
  //           >
  //             {/* Number 102 Village<br></br>
  //             Laemthong Sports Hall Soi Nakhon Laem Thong<br></br>
  //             3, intersection 1-2 road <br></br>Krungthep Kreetha, Saphan Sung
  //             Subdistrict,<br></br> Saphan Sung District, Bangkok 10240 */}
  //           </div>
  //           <div
  //             className="address-mobile suk-semibold"
  //             dangerouslySetInnerHTML={{ __html: t("address") }}
  //           >
  //             {/* Number 102 Village<br></br>
  //             Laemthong Sports Hall Soi Nakhon Laem <br></br> Thong 3,
  //             intersection 1-2 road <br></br>Krungthep Kreetha, Saphan Sung
  //             <br></br> Subdistrict, Saphan Sung District,<br></br> Bangkok 10240 */}
  //           </div>
  //           <div className="contact">
  //             <div className="social">
  //               <img src={Facebook} alt="Facebook"></img>
  //               <div className="social-text suk-semibold">
  //                 MIN-ERGIE DEVELOPMENT
  //               </div>
  //             </div>
  //             <div className="social">
  //               <img src={Instagram} alt="Facebook"></img>
  //               <div className="social-text suk-semibold">
  //                 MIN-ERGIE DEVELOPMENT
  //               </div>
  //             </div>
  //             <div className="social">
  //               <img src={Mail} alt="Facebook"></img>
  //               <div className="social-text suk-semibold">
  //                 MIN-ERGIE@EMAIL.COM
  //               </div>
  //             </div>
  //           </div>
  //           <div className="footer-logo">
  //             <img src={FooterLogo} alt="Footer Logo"></img>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else {
  return (
    <div>
      <nav>
        <div className="top-bar-desktop position-relative w-100">
          <img
            className="position-absolute show-logo show-logo-desktop"
            src={LogoColor}
            alt="mylogo"
          />
        </div>
        <div className="top-bar position-relative w-100">
          <img
            className="position-absolute show-logo show-logo-phone"
            src={logoPhone}
            alt="mylogo"
          />
          {isOpen ? (
            <div
              className={
                isOpen ? "berger-menu animate" : "berger-menu animate-back"
              }
            >
              <div
                className={
                  isOpen ? "berger-item item-forward" : "berger-item item-back"
                }
              >
                <div className="link-show-header suk-bold">{t("menu")}</div>
                <Link
                  to="/"
                  className={
                    "link-show suk-bold " +
                    (currentPath === "/" ? "active" : "")
                  }
                >
                  {t("home")}
                </Link>
                <Link
                  to="/about"
                  className={
                    "link-show suk-bold " +
                    (currentPath === "/about" ? "active" : "")
                  }
                >
                  {t("about")}
                </Link>
                <Link
                  to="/project"
                  className={
                    "link-show suk-bold " +
                    (currentPath === "/project" ? "active" : "")
                  }
                >
                  {t("project")}
                </Link>
                <div className="change-language-mobile suk-medium">
                  <div
                    className={
                      "show-text " +
                      (cookies.get("language") === "en" ? "active" : "")
                    }
                    onClick={() => changeLanguage("en")}
                  >
                    EN
                  </div>
                  |
                  <div
                    className={
                      "show-text " +
                      (cookies.get("language") === "fr" ? "active" : "")
                    }
                    onClick={() => changeLanguage("fr")}
                  >
                    FR
                  </div>
                  |
                  <div
                    className={
                      "show-text " +
                      (cookies.get("language") === "de" ? "active" : "")
                    }
                    onClick={() => changeLanguage("de")}
                  >
                    DE
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="top-bar-nav">
            <div className="d-flex justify-content-between">
              <Link
                to="/"
                className={"link-show " + (currentPath === "/" ? "active" : "")}
              >
                {t("home")}
              </Link>
              <Link
                to="/about"
                className={
                  "link-show " + (currentPath === "/about" ? "active" : "")
                }
              >
                {t("about")}
              </Link>
              <Link
                to="/Project"
                className={
                  "link-show " + (currentPath === "/Project" ? "active" : "")
                }
              >
                {t("project")}
              </Link>
              {/* <Link
                  to="/Service"
                  className={
                    "link-show suk-bold " +
                    (currentPath === "/Service" ? "active" : "")
                  }
                >
                  Service
                </Link> */}
            </div>
          </div>
          <div className="change-language suk-medium">
            <div
              className={
                "show-text " +
                (cookies.get("language") === "en" ? "active" : "")
              }
              onClick={() => changeLanguage("en")}
            >
              EN
            </div>
            |
            <div
              className={
                "show-text " +
                (cookies.get("language") === "fr" ? "active" : "")
              }
              onClick={() => changeLanguage("fr")}
            >
              FR
            </div>
            |
            <div
              className={
                "show-text " +
                (cookies.get("language") === "de" ? "active" : "")
              }
              onClick={() => changeLanguage("de")}
            >
              DE
            </div>
          </div>
          <div className="berger-show">
            <Hamburger
              size={22}
              toggled={isOpen}
              toggle={(val) => {
                setOpen(val);
              }}
            />
          </div>
        </div>
      </nav>
      <Outlet />
      <div className="footer">
        <div className="title suk-bold">{t("contact_us")}</div>
        <div className="group-footer">
          <div
            className="address suk-semibold"
            dangerouslySetInnerHTML={{ __html: t("address") }}
          >
            {/* Number 102 Village<br></br>
              Laemthong Sports Hall Soi Nakhon Laem Thong<br></br>
              3, intersection 1-2 road <br></br>Krungthep Kreetha, Saphan Sung
              Subdistrict,<br></br> Saphan Sung District, Bangkok 10240 */}
          </div>
          <div
            className="address-mobile suk-semibold"
            dangerouslySetInnerHTML={{ __html: t("address") }}
          >
            {/* Number 102 Village<br></br>
              Laemthong Sports Hall Soi Nakhon Laem <br></br> Thong 3,
              intersection 1-2 road <br></br>Krungthep Kreetha, Saphan Sung
              <br></br> Subdistrict, Saphan Sung District,<br></br> Bangkok 10240 */}
          </div>
          <div className="contact">
            <div className="social">
              <img src={Facebook} alt="Facebook"></img>
              <div className="social-text suk-semibold">
                MIN-ERGIE DEVELOPMENT
              </div>
            </div>
            <div className="social">
              <img src={Call} className="image-radius" alt="call"></img>
              <div className="social-text suk-semibold">0925652969</div>
            </div>
            <div className="social">
              <img src={Mail} alt="Facebook"></img>
              <div className="social-text suk-semibold">INFO@MIN-ERGIE.COM</div>
            </div>
          </div>
          <div className="footer-logo">
            <img src={FooterLogo} alt="Footer Logo"></img>
          </div>
        </div>
      </div>
      {/* <FacebookMsg /> */}
    </div>
  );
  // }
}
export default Layout;
