import React, { useState, useEffect} from "react";
import { motion, Variants } from "framer-motion";
import Slider from "../component/slider.js";
import SliderHome from "../component/home/slider-home.js";
import SliderProject from "../component/slider-project.js";
import Bg from "../image/bc1.webp";
import EcoHouse from "../image/eco-house.webp";
import House from "../image/house.webp";
import SmartHouse from "../image/smart-home.webp";
import Circle from "../image/circle.webp";
import image1 from "./../image/bc2.webp";
import image2 from "./../image/bc3.webp";
import "./../css/main.css";
import "./../css/font.css";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import axios from "axios";
function Home() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [canclick, setCanclick] = useState(1);
  const [validEmail, setValidEmail] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { i18n, t } = useTranslation();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleJoin = (e) => {
    setCanclick(0);
    e.preventDefault();
    if (firstname !== "" && lastname !== "" && email !== "" && detail !== "") {
      if (canclick) {
        axios
          .post("https://costcontrol.mirana-th.com/api/mail/send", {
            firstname: firstname,
            lastname: lastname,
            email: email,
            detail: detail,
          })
          .then(
            (result) => {
              setCanclick(1);
              setFirstname("");
              setLastname("");
              setEmail("");
              setDetail("");
              console.log("done");
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } else {
      if (email === "") {
        setValidEmail(1);
      } else {
        setValidEmail(0);
      }
    }
  };
  const image = [
    {
      imgURL: image1,
      imgAlt: "img-1",
    },
    {
      imgURL: image2,
      imgAlt: "img-2",
    },
  ];
  const sliderRight = {
    offscreen: {
      x: 300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.5,
        duration: 1.5,
      },
    },
  };
  const sliderLeft = {
    offscreen: {
      x: -300,
      opacity: 0,
      marginTop: "9.6vw",
    },
    onscreen: {
      x: 0,
      opacity: 1,
      marginTop: "9.6vw",
      transition: {
        type: "spring",
        bounce: 0.5,
        duration: 1.5,
      },
    },
  };
  const sliderMobileUpFade1 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.6,
      },
    },
  };
  const sliderMobileUpFade2 = {
    offscreen: {
      opacity: 0,
      x: "-50%",
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      x: "-50%",
      transition: {
        type: "fade",
        duration: 0.6,
        delay: 0.2,
      },
    },
  };
  const sliderUp1 = {
    offscreen: {
      opacity: 0,
      y: 80,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.8,
      },
    },
  };
  const sliderUp2 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.8,
        delay: 0.4,
      },
    },
  };
  const sliderUp3 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.8,
        delay: 0.6,
      },
    },
  };
  const sliderUp4 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.8,
        delay: 0.8,
      },
    },
  };
  const sliderUp5 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.8,
        delay: 1,
      },
    },
  };
  const sliderLeftImage = {
    offscreen: {
      x: -400,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.5,
        duration: 1.5,
      },
    },
  };
  const showSquare = {
    offscreen: {
      opacity: 0,
      y: -100,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.6,
      },
    },
  };
  const showSquareImg = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.6,
        delay: 0.4,
      },
    },
  };
  const showSquareImg2 = {
    offscreen: {
      opacity: 0,
      scale: 3,
    },
    onscreen: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.2,
      },
    },
  };
  const showSquareText = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1,
      },
    },
  };
  const showSquareText2 = {
    offscreen: {
      opacity: 0,
      y: 40,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.3,
      },
    },
  };
  const images = [
    {
      imgURL: Bg,
      imgAlt: "img-1",
    },
  ];
  return (
    <div className="w-100">
      <Slider images={images}></Slider>

      <div className="show-what-make full-width">
        <div className="row full-width">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className="col-6 position-relative"
          >
            <motion.div
              variants={windowWidth > 430 ? sliderLeft : sliderMobileUpFade1}
            >
              <div
                className="what-div suk-bold"
                dangerouslySetInnerHTML={{ __html: t("differrent") }}
              >
                {/* What makes us differrent<br></br>
                from other real estate? */}
              </div>
            </motion.div>
            {/* <div className="line-what"></div> */}
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className="col-6"
          >
            <motion.div
              className="what-div-detail suk-text"
              variants={windowWidth > 430 ? sliderRight : sliderMobileUpFade2}
              dangerouslySetInnerHTML={{ __html: t("Introducing") }}
            >
              {/* Introducing the Eco-House, Smart House, and<br></br> Luxury Real
              Estate series – redefining contemporary<br></br> living with
              eco-friendly design, cutting-edge<br></br> automation, and bespoke
              luxury. This collection sets<br></br> a new standard for
              sophisticated, sustainable living,<br></br> where innovation meets
              opulence. Welcome to a<br></br> world where eco-consciousness and
              luxury
              <br></br> seamlessly coexist. */}
            </motion.div>
          </motion.div>
        </div>
      </div>
      <div className="space-sector position-relative full-width">
        {/* <div className="slot-line"></div> */}
        <div className="row slot-square full-width">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={
              windowWidth > 430
                ? "col-4"
                : "col-12 d-flex justify-content-center"
            }
          >
            <motion.div
              className="show-square"
              variants={windowWidth > 430 ? showSquare : showSquare}
            >
              <motion.img
                variants={windowWidth > 430 ? showSquareImg : showSquareImg}
                className="icon-sqare"
                src={EcoHouse}
                alt={"eco-house"}
              />
              <motion.img
                variants={windowWidth > 430 ? showSquareImg2 : showSquareImg2}
                className="slot-circle"
                src={Circle}
                alt={"eco-house"}
              />
              <motion.div
                variants={windowWidth > 430 ? showSquareText : showSquareText}
                className="square-text suk-semibold"
              >
                {t("echo-house")}
              </motion.div>
              <motion.div
                variants={windowWidth > 430 ? showSquareText2 : showSquareText2}
                className="square-detail suk-text"
                dangerouslySetInnerHTML={{ __html: t("eco_house_card") }}
              >
                {/* Embrace sustainable luxury with our Eco-House, <br></br>
                where cutting-edge design meets environmentally <br></br>
                conscious living. */}
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={
              windowWidth > 430
                ? "col-4"
                : "col-12 d-flex justify-content-center"
            }
          >
            <motion.div
              className="show-square"
              variants={windowWidth > 430 ? showSquare : showSquare}
            >
              <motion.img
                variants={windowWidth > 430 ? showSquareImg : showSquareImg}
                className="icon-sqare"
                src={SmartHouse}
                alt={"eco-house"}
              />
              <motion.img
                variants={windowWidth > 430 ? showSquareImg2 : showSquareImg2}
                className="slot-circle"
                src={Circle}
                alt={"eco-house"}
              />
              <motion.div
                variants={windowWidth > 430 ? showSquareText : showSquareText}
                className="square-text suk-semibold"
              >
                {t("smart-iot")}
              </motion.div>
              <motion.div
                variants={windowWidth > 430 ? showSquareText2 : showSquareText2}
                className="square-detail suk-text"
                dangerouslySetInnerHTML={{ __html: t("smart_iot_card") }}
              >
                {/* Transform your lifestyle with our Smart House, <br></br>
                seamlessly integrating cutting-edge technology for <br></br>
                an unparalleled experience of convenience <br></br>
                and innovation. */}
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={
              windowWidth > 430
                ? "col-4"
                : "col-12 d-flex justify-content-center"
            }
          >
            <motion.div
              className="show-square"
              variants={windowWidth > 430 ? showSquare : showSquare}
            >
              <motion.img
                variants={windowWidth > 430 ? showSquareImg : showSquareImg}
                className="icon-sqare"
                src={House}
                alt={"eco-house"}
              />
              <motion.img
                variants={windowWidth > 430 ? showSquareImg2 : showSquareImg2}
                className="slot-circle"
                src={Circle}
                alt={"eco-house"}
              />
              <motion.div
                variants={windowWidth > 430 ? showSquareText : showSquareText}
                className="square-text suk-semibold"
              >
                {t("luxury")}
              </motion.div>
              <motion.div
                variants={windowWidth > 430 ? showSquareText2 : showSquareText2}
                className="square-detail suk-text"
                dangerouslySetInnerHTML={{ __html: t("luxury_card") }}
              >
                {/* Discover unparalleled sophistication and indulgence in our
                <br></br>
                Luxury House collection, where opulent design meets refined
                <br></br>
                living at its finest. */}
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      {windowWidth > 430 ? (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="why-choose space-sector full-width"
          style={{ marginBottom: "50vh" }}
        >
          {/* <motion.img
            variants={{
              offscreen: {
                x: -200,
                opacity: 0,
              },
              onscreen: {
                x: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.5,
                  duration: 1.6,
                  delay: 1,
                },
              },
            }}
            className="why-choose-image"
            src={WhyChoose}
            alt=""
          ></motion.img>
          <motion.img
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.5,
                  duration: 1.6,
                  delay: 1.5,
                },
              },
            }}
            className="why-choose-image-2"
            src={WhyChoose2}
            alt=""
          ></motion.img> */}
          <motion.div
            variants={{
              offscreen: {
                y: "5vw",
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.5,
                  duration: 1.6,
                  delay: 1,
                },
              },
            }}
            className="show-slider-image"
          >
            <SliderHome image={image}></SliderHome>
          </motion.div>
          <motion.div
            variants={{
              offscreen: {
                y: 200,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.5,
                  duration: 1,
                },
              },
            }}
            className="w-100 background-why-choose full-width"
          >
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 0.6,
                  },
                },
              }}
              className="main-why suk-semibold"
            >
              {t("why_choose_us")}
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 0.8,
                  },
                },
              }}
              className="second-why semi-bold"
            >
              {t("company-limited")}
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.6,
                    delay: 1,
                  },
                },
              }}
              className="detail-why semi-text"
              dangerouslySetInnerHTML={{ __html: t("why_choose_us_reson") }}
            >
              {/* Welcome to a world where Eco-House, Smart<br></br> House, and
              Luxury Real Estate redefine modern<br></br> living with
              eco-friendly design, cutting-edge<br></br> automation, and bespoke
              luxury—setting a new<br></br> standard for sophisticated,
              sustainable living
              <br></br> where innovation meets opulence. */}
            </motion.div>
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="why-choose space-sector full-width"
        >
          {/* <motion.img
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "fade",
                  duration: 0.5,
                },
              },
            }}
            className="why-choose-image"
            src={WhyChoose}
            alt=""
          ></motion.img>
          <motion.img
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "fade",
                  duration: 0.5,
                  delay: 0.3,
                },
              },
            }}
            className="why-choose-image-2"
            src={WhyChoose2}
            alt=""
          ></motion.img> */}
          <motion.div
            variants={{
              offscreen: {
                y: "5vw",
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  bounce: 0.5,
                  duration: 1.6,
                  delay: 1,
                },
              },
            }}
            className="show-slider-image"
          >
            <SliderHome image={image}></SliderHome>
          </motion.div>
          <motion.div className="w-100 background-why-choose full-width text-center">
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 0.6,
                  },
                },
              }}
              className="main-why suk-semibold"
            >
              {t("why_choose_us")}
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 0.8,
                  },
                },
              }}
              className="second-why suk-bold"
            >
              Min-Ergie Development Company Limited
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 40,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 1,
                  },
                },
              }}
              className="detail-why suk-text"
              dangerouslySetInnerHTML={{ __html: t("why_choose_us_reson") }}
            >
              {/* Welcome to a world where Eco-House, Smart House, and<br></br>
              Luxury Real Estate redefine modern living with eco-friendly
              <br></br> design, cutting-edge automation, and bespoke luxury—
              <br></br>setting a new standard for sophisticated, sustainable
              living<br></br> where innovation meets opulence. */}
            </motion.div>
          </motion.div>
        </motion.div>
      )}
      {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className="our-project space-sector full-width"
      >
        <motion.img
          className="our-project-image"
          src={OurProjuctShow}
          variants={windowWidth > 430 ? sliderLeftImage : sliderMobileUpFade1}
          alt=""
        ></motion.img>
        <div className="our-project-text">
          <motion.div
            className="our-main suk-bold"
            variants={windowWidth > 430 ? sliderUp1 : sliderUp1}
          >
            {t("our_projects")}
          </motion.div>
          <motion.div
            className="our-list suk-bold"
            variants={windowWidth > 430 ? sliderUp2 : sliderUp2}
          >
            {t("pattaya")}
          </motion.div>
          <motion.div
            className="our-list suk-bold"
            variants={windowWidth > 430 ? sliderUp3 : sliderUp3}
          >
            {t("chiang_mai")}
          </motion.div>
          <motion.div
            className="our-list suk-bold"
            variants={windowWidth > 430 ? sliderUp4 : sliderUp4}
          >
            {t("ภูเก็ต")}
          </motion.div>
          <motion.div
            className="our-list suk-bold"
            variants={windowWidth > 430 ? sliderUp5 : sliderUp5}
          >
            {t("หัวหิน")}
          </motion.div>
        </div>
      </motion.div> */}
    </div>
  );
}

export default Home;
