import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as THREE from "three";
import React, { useRef, useState } from "react";
// small change
class Model extends React.Component {
  constructor(props) {
    super(props);
    this.state = { model: null, data: null, path: props.path };
  }
  // componentDidUpdate() {
  //   this.state.data.traverse((mod) => {
  //     if (mod.isMesh) {
  //       mod.material.opacity = this.props.opacity;
  //       mod.material.transparent = true;
  //     }
  //   });
  // }
  componentDidMount() {
    const model = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/");
    model.setDRACOLoader(dracoLoader);
    model.load(
      process.env.PUBLIC_URL + this.state.path,
      function (gltf) {
        var modelgltf = gltf.scene;
        try {
          modelgltf.traverse((mod) => {
            if (mod.isMesh) {
              mod.material.opacity = this.props.opacity;
              mod.material.transparent = true;
            }
          });
        } catch (e) {
          console.log(e);
        }

        this.setState({ data: gltf.scene });
      }.bind(this),
      function (xhr) {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        if ((xhr.loaded / xhr.total) * 100 === 100) {
          // setTimeout(() => {
          //   this.props.finishLoad();
          // }, 1000);
        }
      }.bind(this),
      // called when loading has errors
      function (error) {
        console.log(error);
        console.log("An error happened");
      }
    );
  }
  render() {
    return this.state.data ? (
      this.props.scale ? (
        <primitive
          object={this.state.data}
          scale={this.props.scale}
          position={this.props.position}
        />
      ) : (
        <primitive object={this.state.data} position={this.props.position} />
      )
    ) : null;
  }
}

export default Model;
