import React, { useState, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";
import "./../../css/font.css";
import "./../../css/project.css";
function Slider({ images }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const swiperRef = useRef(null);
  return (
    <Swiper
      // spaceBetween={50}
      slidesPerView={1}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      loop={true}
      pagination={false}
      modules={[Pagination]}
      scrollbar={{ draggable: true }}
      navigation
      onSlideChange={() => console.log("slide change")}
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <img
              className="w-100 image-show"
              src={image.imgURL}
              alt={image.imgAlt}
            />
          </SwiperSlide>
        );
      })}
      <div
        className="image-btn-left"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <GoChevronLeft className="i-con-white" />
      </div>
      <div
        className="image-btn-right"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <GoChevronRight className="i-con-white" />
      </div>
    </Swiper>
  );
}

export default Slider;
