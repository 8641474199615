import component1 from "../../image/project/componentPic/1.webp";
import component2 from "../../image/project/componentPic/2.webp";
import component3 from "../../image/project/componentPic/3.webp";
import componentBase from "../../image/project/componentPic/base.webp";
import "./../../css/project/show-component.css";
import { motion, Variants } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function ShowComponent({ innerWidth }) {
  const { i18n, t } = useTranslation();
  const animation = {
    rest: {
      x: 0,
      y: 0,
    },
    hover: {
      x: "0",
      y: "0vw",
      transition: {
        type: "fade",
        duration: 0.8,
      },
    },
  };
  const animation2 = {
    rest: {
      x: 0,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
      },
    },
    hover: {
      x: "0",
      y:
        innerWidth <= 768 && innerWidth > 250
          ? "-13vw"
          : innerWidth <= 1024 && innerWidth > 768
          ? "-10vw"
          : "-8vw",
      transition: {
        type: "fade",
        duration: 0.6,
      },
    },
  };
  const animation3 = {
    rest: {
      x: 0,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
    hover: {
      x: "0",
      y:
        innerWidth <= 768 && innerWidth > 250
          ? "-23vw"
          : innerWidth < 1024 && innerWidth > 768
          ? "-18vw"
          : "-16vw",
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const animation4 = {
    rest: {
      x: 0,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.6,
      },
    },
    hover: {
      x: "0",
      y:
        innerWidth <= 768 && innerWidth > 250
          ? "-33vw"
          : innerWidth < 1024 && innerWidth > 768
          ? "-27vw"
          : "-24vw",
      transition: {
        type: "fade",
        duration: 0.4,
      },
    },
  };
  const textUp = {
    rest: {
      y: "5vw",
      opacity: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.35,
      },
    },
    hover: {
      y: 0,
      opacity: 1,
      transition: {
        type: "fade",
        duration: 0.3,
      },
    },
  };
  const textUp2 = {
    rest: {
      y: "5vw",
      opacity: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.25,
      },
    },
    hover: {
      y: 0,
      opacity: 1,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.15,
      },
    },
  };
  const textUp3 = {
    rest: {
      y: "5vw",
      opacity: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.15,
      },
    },
    hover: {
      y: 0,
      opacity: 1,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.25,
      },
    },
  };
  const textUp4 = {
    rest: {
      y: "5vw",
      opacity: 0,
      transition: {
        type: "fade",
        duration: 0.25,
      },
    },
    hover: {
      y: 0,
      opacity: 1,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.35,
      },
    },
  };
  return innerWidth > 1024 ? (
    <motion.div
      initial="rest"
      whileHover="hover"
      animate="rest"
      className="component-container"
    >
      <motion.div>
        <motion.img
          variants={animation}
          src={componentBase}
          className="image-show-stack-base"
          alt="component-base"
        />
        <motion.img
          variants={animation2}
          src={component1}
          className="image-show-stack-1"
          alt="component-1"
        />
        <motion.img
          variants={animation3}
          src={component2}
          className="image-show-stack-2"
          alt="component-2"
        />
        <motion.img
          variants={animation4}
          src={component3}
          className="image-show-stack-3"
          alt="component-3"
        />
      </motion.div>
      <motion.div className="text-sector">
        <motion.div variants={textUp4} className="main-text suk-bold">
          {t("project_roof_1")}
        </motion.div>
        <motion.ul>
          <motion.li variants={textUp4}>{t("project_roof_2")}</motion.li>
          <motion.li variants={textUp4}>{t("project_roof_3")}</motion.li>
          <motion.li variants={textUp4}>{t("project_roof_4")}</motion.li>
        </motion.ul>
        <motion.div variants={textUp3} className="main-text suk-bold">
          {t("project_structural_1")}
        </motion.div>
        <motion.ul>
          <motion.li variants={textUp3}>{t("project_structural_2")}</motion.li>
          <motion.li variants={textUp3}>{t("project_structural_3")}</motion.li>
          <motion.li variants={textUp3}> {t("project_structural_4")}</motion.li>
          <motion.li variants={textUp3}>{t("project_structural_5")}</motion.li>
        </motion.ul>
        <motion.div variants={textUp2} className="main-text suk-bold">
          {t("project_architectural_1")}
        </motion.div>
        <motion.ul>
          <motion.li variants={textUp2}>
            {t("project_architectural_2")}
          </motion.li>
          <motion.li variants={textUp2}>
            {t("project_architectural_3")}
          </motion.li>
          <motion.li variants={textUp2}>
            {t("project_architectural_4")}
          </motion.li>
          <motion.li variants={textUp2}>
            {t("project_architectural_5")}
          </motion.li>
          <motion.li variants={textUp2}>
            {t("project_architectural_6")}
          </motion.li>
        </motion.ul>
        <motion.div variants={textUp} className="main-text suk-bold">
          {t("project_overall_form_1")}
        </motion.div>
      </motion.div>
    </motion.div>
  ) : (
    <motion.div
      initial="rest"
      whileInView="hover"
      viewport={{ once: true }}
      className="component-container"
    >
      <motion.div>
        <motion.img
          variants={animation}
          src={componentBase}
          className="image-show-stack-base"
          alt="component-base"
        />
        <motion.img
          variants={animation2}
          src={component1}
          className="image-show-stack-1"
          alt="component-1"
        />
        <motion.img
          variants={animation3}
          src={component2}
          className="image-show-stack-2"
          alt="component-2"
        />
        <motion.img
          variants={animation4}
          src={component3}
          className="image-show-stack-3"
          alt="component-3"
        />
      </motion.div>
      <motion.div className="text-sector">
        <motion.div variants={textUp4} className="main-text suk-bold">
          {t("project_roof_1")}
        </motion.div>
        <motion.ul>
          <motion.li className="detail-text" variants={textUp4}>
            {t("project_roof_2")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp4}>
            {t("project_roof_3")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp4}>
            {t("project_roof_4")}
          </motion.li>
        </motion.ul>
        <motion.div variants={textUp3} className="main-text suk-bold">
          {t("project_structural_1")}
        </motion.div>
        <motion.ul>
          <motion.li className="detail-text" variants={textUp3}>
            {t("project_structural_2")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp3}>
            {t("project_structural_3")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp3}>
            {t("project_structural_4")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp3}>
            {t("project_structural_5")}
          </motion.li>
        </motion.ul>
        <motion.div variants={textUp2} className="main-text suk-bold">
          {t("project_architectural_1")}
        </motion.div>
        <motion.ul>
          <motion.li className="detail-text" variants={textUp2}>
            {t("project_architectural_2")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp2}>
            {t("project_architectural_3")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp2}>
            {t("project_architectural_4")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp2}>
            {t("project_architectural_5")}
          </motion.li>
          <motion.li className="detail-text" variants={textUp2}>
            {t("project_architectural_6")}
          </motion.li>
        </motion.ul>
        <motion.div variants={textUp} className="main-text suk-bold">
          {t("project_overall_form_1")}
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
export default ShowComponent;
