import React, { useState, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./../css/main.css";
import "./../css/slider-project.css";
function Slider({ images }) {
  return (
    <Swiper
      initialSlide={1}
      slidesPerView={"auto"}
      centeredSlides={true}
      spaceBetween={"60vw"}
      className="mySwiper"
      // loop={true}
    >
      {images.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <img
              className="image-slider"
              src={image.imgURL}
              alt={image.imgAlt}
            />
            <div className="show-text-slider text-center suk-bold mt-2">
              MIN-ERGIE VILLA {index + 1}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Slider;
