import "./../../css/project/show-component.css";
import { motion, Variants } from "framer-motion";
import React, { useState } from "react";
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import Model from "./model.js";
import ModelAnimation from "./model-animation.js";
import Dargable from "./dargable.js";
import Orbit from "./orbit.js";
import { Physics } from "@react-three/cannon";
function ShowComponent(props) {
  return (
    <Canvas
      shadows={true}
      style={{
        // background: "lightblue",
        backgroundColor: "rgba(234, 191, 127, 0.5)",
        marginTop: "5vw",
        height: "70vh",
      }}
      camera={{ position: [-10, 0, 20] }}
    >
      <ambientLight intensity={2} />
      <pointLight position={[0, 0, 0]} intensity={100} />
      <Orbit />
      {/* <Physics> */}
      {/* <Dargable click={props.click}> */}
      <Model
        path="/models/test.glb"
        position={[0, -4, 0]}
        scale={new Array(3).fill(1)}
        opacity={1}
      />
      {/* </Dargable> */}
      {/* </Physics> */}
    </Canvas>
  );
}
export default ShowComponent;
