import logo from "./logo.svg";
import "./App.css";
import React, { useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from "./page/Home.js";
import Project from "./page/Project.js";
import About from "./page/About.js";
import Layout from "./component/Layout.js";
import DemoTest from "./page/DemoTest.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
function App() {
  const { i18n, t } = useTranslation();
  const [isOpen, setOpen] = useState(true);
  const cookies = new Cookies();
  if (isOpen) {
    setOpen(false);
    if (!cookies.get("language")) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
      cookies.set("language", "en", {
        path: "/",
        expires: expirationDate,
      });
    } else {
      i18n.changeLanguage(cookies.get("language"));
    }
  }
  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <Routes>
          {/* <Route path="/" element={<Layout />}> */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="project" element={<Project />} />
            <Route path="demotest" element={<DemoTest />} />
          </Route>
        </Routes>
      </I18nextProvider>
    </div>
  );
}

// function Layout() {
//   return (
//     <div>
//       {/* A "layout route" is a good place to put markup you want to
//           share across all the pages on your site, like navigation. */}
//       <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/about">About</Link>
//           </li>
//           <li>
//             <Link to="/dashboard">Dashboard</Link>
//           </li>
//           <li>
//             <Link to="/nothing-here">Nothing Here</Link>
//           </li>
//         </ul>
//       </nav>

//       <hr />

//       {/* An <Outlet> renders whatever child route is currently active,
//           so you can think about this <Outlet> as a placeholder for
//           the child routes we defined above. */}
//       <Outlet />
//     </div>
//   );
// }

// function About() {
//   return (
//     <div>
//       <h2>About</h2>
//     </div>
//   );
// }

// function Project() {
//   return (
//     <div>
//       <h2>Project</h2>
//     </div>
//   );
// }

export default App;
