import "./../../css/project/show-list.css";
import { motion, Variants } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function ShowComponent({ image }) {
  const { i18n, t } = useTranslation();
  const textUp = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
      },
    },
  };
  const textUp2 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.2,
      },
    },
  };
  const textUp3 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.3,
      },
    },
  };
  const textUp4 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.4,
      },
    },
  };
  const textUp5 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.5,
      },
    },
  };
  const textUp6 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.6,
      },
    },
  };
  const textUp7 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.7,
      },
    },
  };
  const textUp8 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.8,
      },
    },
  };
  const textUp9 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 0.9,
      },
    },
  };
  const textUp10 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 1,
      },
    },
  };
  const textUp11 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 1.1,
      },
    },
  };
  const textUp12 = {
    offscreen: {
      x: "-25vw",
    },
    onscreen: {
      x: 0,
      transition: {
        type: "fade",
        duration: 0.3,
        delay: 1.2,
      },
    },
  };
  const animationImage = {
    offscreen: {
      y: "-33.4vwvw",
    },
    onscreen: {
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const animationImage2 = {
    offscreen: {
      y: "33.4vwvw",
    },
    onscreen: {
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const animationImage3 = {
    offscreen: {
      y: "-33.4vwvw",
    },
    onscreen: {
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const animationImage4 = {
    offscreen: {
      y: "33.4vwvw",
    },
    onscreen: {
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      className="row image-list"
      style={{ height: "100%", width: "100vw", marginTop: "20vw" }}
    >
      <motion.div className="col-8 d-flex show-image-div">
        <motion.div
          variants={animationImage}
          className="image-part"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPositionX: "0%",
          }}
          alt="jame"
        ></motion.div>
        <motion.div
          variants={animationImage2}
          className="image-part"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPositionX: "33.44%",
          }}
          alt="jame"
        ></motion.div>
        <motion.div
          variants={animationImage3}
          className="image-part"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPositionX: "66.73%",
          }}
          alt="jame"
        ></motion.div>
        <motion.div
          variants={animationImage4}
          className="image-part"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: "99.98%",
          }}
          alt="jame"
        ></motion.div>
      </motion.div>
      <div className="col-4 position-relative parent-container">
        <div className="list-container">
          <motion.div className="text-sector">
            <motion.div variants={textUp} className="list-title suk-bold">
              {t("project_villa_pattaya")}
            </motion.div>
            <motion.div
              variants={textUp2}
              className={
                "list-sub-title suk-semibold " +
                (i18n.language === "fr" ? "list-sub-title-fr" : "")
              }
            >
              {t("project_price")}
            </motion.div>
            <motion.div variants={textUp3} className="list-detail suk-medium">
              {t("project_include_1")}
            </motion.div>
            <motion.div variants={textUp4} className="list-detail suk-medium">
              {t("project_include_2")}
            </motion.div>
            <motion.div variants={textUp5} className="list-detail suk-medium">
              {t("project_include_3")}
            </motion.div>
            <motion.div variants={textUp6} className="list-detail suk-medium">
              {t("project_include_4")}
            </motion.div>
            <motion.div variants={textUp7} className="list-detail suk-medium">
              {t("project_include_5")}
            </motion.div>
            <motion.div variants={textUp8} className="list-detail suk-medium">
              {t("project_include_6")}
            </motion.div>
            <motion.div variants={textUp9} className="list-detail suk-medium">
              {t("project_include_7")}
            </motion.div>
            <motion.div variants={textUp10} className="list-detail suk-medium">
              {t("project_include_8")}
            </motion.div>
            <motion.div variants={textUp11} className="list-detail suk-medium">
              {t("project_include_9")}
            </motion.div>
            <motion.div variants={textUp12} className="list-detail suk-medium">
              {t("project_include_10")}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}
export default ShowComponent;
