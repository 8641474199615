import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { extend, useThree } from "@react-three/fiber";
extend({ OrbitControls });
const Orbit = () => {
  const { camera, gl } = useThree();
  camera.lookAt(30, 0, 20);
  camera.position.set(-10, 0, 20);
  return (
    <orbitControls
      minDistance="26"
      maxDistance="46"
      enablePan={false}
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={0}
      zoomSpeed="20"
      target={[30, 0, 20]}
      attach="orbitControls"
      args={[camera, gl.domElement]}
    />
  );
};
export default Orbit;
