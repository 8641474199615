import around1 from "../../image/project/aroundPicture/1.webp";
import around2 from "../../image/project/aroundPicture/2.webp";
import around3 from "../../image/project/aroundPicture/3.webp";
import around4 from "../../image/project/aroundPicture/4.webp";
import "./../../css/project/show-around.css";
import { motion, Variants } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function ShowAround() {
  const { i18n, t } = useTranslation();
  const [select, setSelect] = useState(1);
  const [first, setFirst] = useState(false);
  const [prepair, setPrepair] = useState(false);
  const sliderUp = {
    unselect: {
      opacity: 0,
      y: 50,
    },
    selected: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
        delay: 1.1,
      },
    },
  };
  const sliderUp2 = {
    unselect: {
      opacity: 0,
      y: 50,
    },
    selected: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
        delay: 1.3,
      },
    },
  };
  const sliderRight = {
    offscreen: {
      opacity: 0,
      x: 50,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const sliderRightBig = {
    offscreen: {
      opacity: 0,
      x: 50,
      y: 0,
      scale: 0.5,
    },
    onscreen: {
      opacity: 1,
      x: "-30vw",
      y: "10vw",
      scale: 2,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  const sliderRight2 = {
    offscreen: {
      opacity: 0,
      x: 50,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 0.4,
      },
    },
  };
  const sliderRight3 = {
    offscreen: {
      opacity: 0,
      x: 50,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 0.8,
      },
    },
  };
  const sliderRight4 = {
    offscreen: {
      opacity: 0,
      x: 50,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.2,
      },
    },
  };
  const colorChange = {
    select1: {
      width: "30vw",
      backgroundColor: "rgba(234,191,127,0.5)",
      transition: {
        type: "spring",
        duration: 0.7,
      },
    },
    select2: {
      width: "30vw",
      backgroundColor: "rgba(140,158,147,0.5)",
      transition: {
        type: "spring",
        duration: 0.7,
      },
    },
    select3: {
      width: "30vw",
      backgroundColor: "rgba(166,142,116,0.5)",
      transition: {
        type: "spring",
        duration: 0.7,
      },
    },
    select4: {
      width: "30vw",
      backgroundColor: "rgba(115,114,109,0.5)",
      transition: {
        type: "spring",
        duration: 0.7,
      },
    },
    prepair: {
      width: 0,
      transition: {
        type: "spring",
        duration: 0.7,
        // delay: 0.7,
      },
    },
  };
  const showVariants = {
    unselect: {
      x: 0,
      y: 0,
      scale: 1,
    },
    selected: {
      x: "-30vw",
      y: "10vw",
      scale: 3.2,
      zIndex: -99999,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  const showVariants2 = {
    unselect: {
      x: 0,
      y: 0,
      scale: 1,
    },
    selected: {
      x: "-30vw",
      scale: 3.2,
      y: "2vw",
      zIndex: -99999,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  const showVariants3 = {
    unselect: {
      x: 0,
      y: 0,
      scale: 1,
    },
    selected: {
      x: "-30vw",
      y: "-7vw",
      scale: 3.2,
      zIndex: -99999,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  const showVariants4 = {
    unselect: {
      x: 0,
      y: 0,
      scale: 1,
    },
    selected: {
      x: "-30vw",
      y: "-15vw",
      scale: 3.2,
      zIndex: -99999,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  const showText = {
    unselect: {
      y: 50,
      opacity: 0,
    },
    selected: {
      y: 0,
      opacity: 1,
      transition: {
        type: "fade",
        duration: 0.7,
      },
    },
  };
  return (
    <div className="around-container">
      <motion.div
        // initial="offscreen"
        // whileInView="onscreen"
        // viewport={{ once: true }}
        variants={colorChange}
        className="show-text-left"
        animate={
          prepair
            ? "prepair"
            : select === 1
            ? "select1"
            : select === 2
            ? "select2"
            : select === 3
            ? "select3"
            : "select4"
        }
      >
        <motion.div
          variants={sliderUp}
          animate={select === 1 ? "selected" : "unselect"}
          className={
            "title-text suk-bold " +
            (select === 1 ? "" : "d-none") +
            " " +
            (i18n.language === "fr" ? "title-text-fr" : "")
          }
        >
          {t("project_ventilation")}
        </motion.div>
        <motion.div
          variants={sliderUp2}
          animate={select === 1 ? "selected" : "unselect"}
          className={"detail-text suk-medium " + (select === 1 ? "" : "d-none")}
        >
          {t("project_ventilation_data")}
        </motion.div>
        <motion.div
          variants={sliderUp}
          animate={select === 2 ? "selected" : "unselect"}
          className={
            "title-text suk-bold " +
            (select === 2 ? "" : "d-none") +
            " " +
            (i18n.language === "fr" ? "title-text-fr" : "")
          }
        >
          {t("project_regional")}
        </motion.div>
        <motion.div
          variants={sliderUp2}
          animate={select === 2 ? "selected" : "unselect"}
          className={
            "detail-text suk-medium " +
            (select === 2 ? "" : "d-none") +
            " " +
            (i18n.language === "fr" ? "detail-text-fr" : "") +
            " " +
            (i18n.language === "de" ? "detail-text-de" : "")
          }
        >
          {t("project_regional_data")}
        </motion.div>
        <motion.div
          variants={sliderUp}
          animate={select === 3 ? "selected" : "unselect"}
          className={"title-text suk-bold " + (select === 3 ? "" : "d-none")}
        >
          {t("project_noise_accessibility")}
        </motion.div>
        <motion.div
          variants={sliderUp2}
          animate={select === 3 ? "selected" : "unselect"}
          className={
            "detail-text suk-medium " +
            (select === 3 ? "" : "d-none") +
            " " +
            (i18n.language === "fr" ? "detail-text-fr" : "") +
            " " +
            (i18n.language === "de" ? "detail-text-de" : "")
          }
        >
          {t("project_noise_data")}
        </motion.div>
        <motion.div
          variants={sliderUp}
          animate={select === 4 ? "selected" : "unselect"}
          className={"title-text suk-bold " + (select === 4 ? "" : "d-none")}
        >
          {t("project_circulation_route")}
        </motion.div>
        <motion.div
          variants={sliderUp2}
          animate={select === 4 ? "selected" : "unselect"}
          className={
            "detail-text suk-medium " +
            (select === 4 ? "" : "d-none") +
            " " +
            (i18n.language === "fr" ? "detail-text-fr" : "") +
            " " +
            (i18n.language === "de" ? "detail-text-de" : "")
          }
        >
          {t("project_circulation_data")}
        </motion.div>
      </motion.div>
      {/* <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className="image-sector"
      >
        <motion.img
          className={"image-show-left " + (select === 1 ? "d-none" : "")}
          variants={sliderRight}
          src={around1}
          alt={"around-1"}
          onClick={() => setSelect((select1) => 1)}
        />
        <motion.img
          className={"image-show-big " + (first ? "d-none" : "")}
          variants={sliderRightBig}
          src={around1}
          alt={"around-big-1"}
        />
        <motion.img
          className={"image-show-left " + (select === 2 ? "d-none" : "")}
          variants={sliderRight2}
          src={around2}
          alt={"around-2"}
          onClick={() => {
            setSelect((select) => 2);
            setFirst(true);
          }}
        />

        <motion.img
          className={"image-show-left " + (select === 3 ? "d-none" : "")}
          variants={sliderRight3}
          src={around3}
          alt={"around-3"}
          onClick={() => {
            setSelect((select) => 3);
            setFirst(true);
          }}
        />
        <motion.img
          className={"image-show-left " + (select === 4 ? "d-none" : "")}
          variants={sliderRight4}
          src={around4}
          alt={"around-4"}
          onClick={() => {
            setSelect((select) => 4);
            setFirst(true);
          }}
        />
      </motion.div> */}
      {/* <motion.img
        className={"image-show-big " + (first ? "" : "d-none")}
        variants={showVariants}
        src={around1}
        animate={select === 1 ? "selected" : "unselect"}
        alt={"around-big-1"}
      />
      <motion.img
        className={"image-show-big " + (first ? "" : "d-none")}
        variants={showVariants}
        src={around2}
        animate={select === 2 ? "selected" : "unselect"}
        alt={"around-big-2"}
      />
      <motion.img
        className={"image-show-big " + (first ? "" : "d-none")}
        variants={showVariants}
        src={around3}
        animate={select === 3 ? "selected" : "unselect"}
        alt={"around-big-3"}
      />
      <motion.img
        className={"image-show-big " + (first ? "" : "d-none")}
        variants={showVariants}
        src={around4}
        animate={select === 4 ? "selected" : "unselect"}
        alt={"around-big-4"}
      /> */}
      <motion.div className="image-sector">
        <motion.img
          className={"image-show-left " + (select === 1 ? "hover-not" : "")}
          variants={showVariants}
          src={around2}
          alt={"around-1"}
          animate={select === 1 ? "selected" : "unselect"}
          onClick={() => {
            if (select !== 1) {
              setSelect((select) => 1);
              setPrepair(true);
              setTimeout(() => {
                setPrepair(false);
              }, 700);
            }
          }}
        />
        <motion.img
          className={"image-show-left " + (select === 2 ? "hover-not" : "")}
          variants={showVariants2}
          src={around3}
          alt={"around-2"}
          animate={select === 2 ? "selected" : "unselect"}
          onClick={() => {
            if (select !== 2) {
              setSelect((select) => 2);
              setFirst(true);
              setPrepair(true);
              setTimeout(() => {
                setPrepair(false);
              }, 700);
            }
          }}
        />

        <motion.img
          className={"image-show-left " + (select === 3 ? "hover-not" : "")}
          variants={showVariants3}
          src={around4}
          alt={"around-3"}
          animate={select === 3 ? "selected" : "unselect"}
          onClick={() => {
            if (select !== 3) {
              setSelect((select) => 3);
              setFirst(true);
              setPrepair(true);
              setTimeout(() => {
                setPrepair(false);
              }, 700);
            }
          }}
        />
        <motion.img
          className={"image-show-left " + (select === 4 ? "hover-not" : "")}
          variants={showVariants4}
          src={around1}
          alt={"around-4"}
          animate={select === 4 ? "selected" : "unselect"}
          onClick={() => {
            if (select !== 4) {
              setSelect((select) => 4);
              setPrepair(true);
              setTimeout(() => {
                setPrepair(false);
              }, 700);
            }
          }}
        />
      </motion.div>
      <motion.div className="text-image-sector">
        <motion.div
          variants={showText}
          animate={select === 1 ? "selected" : "unselect"}
          className={"image-text suk-bold " + (select === 1 ? "" : "d-none")}
        >
          {t("project_our_ventilation_one")}
        </motion.div>
        <motion.div
          variants={showText}
          animate={select === 2 ? "selected" : "unselect"}
          className={"image-text suk-bold " + (select === 2 ? "" : "d-none")}
        >
          {t("project_sun_path")}
        </motion.div>
        <motion.div
          variants={showText}
          animate={select === 3 ? "selected" : "unselect"}
          className={"image-text suk-bold " + (select === 3 ? "" : "d-none")}
        >
          {t("project_noise")}
        </motion.div>
        <motion.div
          variants={showText}
          animate={select === 4 ? "selected" : "unselect"}
          className={"image-text suk-bold " + (select === 4 ? "" : "d-none")}
        >
          {t("project_circulation")}
        </motion.div>
      </motion.div>
    </div>
  );
}
export default ShowAround;
