import "./../css/about.css";
import { delay, motion, Variants } from "framer-motion";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
//new about
import CEO from "./../image/about/ceo.webp";
function About() {
  const { i18n, t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sliderLeft = {
    offscreen: {
      x: -300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.5,
        delay: 0.5,
        duration: 0.8,
      },
    },
  };
  const sliderRight = {
    offscreen: {
      x: 300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.5,
        delay: 0.5,
        duration: 0.8,
      },
    },
  };
  const sliderUp = {
    offscreen: {
      opacity: 0,
      y: 80,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
      },
    },
  };
  const sliderUp2 = {
    offscreen: {
      opacity: 0,
      y: 80,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
        delay: 0.3,
      },
    },
  };
  const sliderUp3 = {
    offscreen: {
      opacity: 0,
      y: 80,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.4,
        delay: 0.6,
      },
    },
  };
  return (
    <div className="about">
      <div className="background-about">
        {/* <LayoutAbout></LayoutAbout> */}
        <div className="d-flex justify-content-center">
          <img src={CEO} alt="circle" className="image-circle"></img>
        </div>
        <div className="d-flex justify-content-center">
          <div className="about-text-sector">
            <div className="main-text suk-bold">Thakrit Kamolwekin</div>
            <div className="position-text suk-bold">
              Executive chairman & founder
            </div>
            <div className="position-text suk-bold half-show">
              The team leader of MIN-ERGIE Co., Ltd.,
            </div>
          </div>
        </div>
      </div>
      <motion.div className="about-me">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="about-me-sector"
        >
          <div className="main-me-text suk-bold">
            <LetterAnimation text="Our Mission" />
          </div>
          <motion.div variants={sliderLeft} className="detail-me-text suk-thin">
            Min-Ergie that align with the current era's environmental concerns
            are in demand. We propose a land allocation project for like-minded
            individuals committed to preserving our planet. This initiative aims
            to provide sustainable housing solutions for inhabitants who share
            our vision for a greener world.
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div className="about-me our-vision">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="about-me-sector"
        >
          <motion.div className="main-me-text suk-bold">
            <LetterAnimation text="Our Vision" />
          </motion.div>
          <motion.div
            variants={sliderRight}
            className="detail-me-text suk-thin"
          >
            A plan for a new business aimed at promoting environmental
            conservation and fostering community engagement, with a focus on
            creating high-quality living standards together.
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div className="about-me">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="about-me-sector"
        >
          <div className="main-me-text suk-bold">
            <LetterAnimation text="Our Process" />
          </div>
          <motion.div variants={sliderLeft} className="detail-me-text suk-thin">
            In the future, we plan to create collaborative employment
            opportunities that prioritize quality and work together to preserve
            the Earth for a better shared living environment.
          </motion.div>
        </motion.div>
      </motion.div>
      {/* <div className="about-me">
        <div className="about-me-sector">
          <div className="detail-me-text suk-thin">
            "Our core belief in business is honesty towards the residents we
            serve." "We dream of a business that fosters sustainable home
            construction, and we are committed to growing and developing this
            business further.""Our key strength in the services we provide to
            residents is our collaborative approach to preserving the
            environment together."
          </div>
        </div>
      </div> */}
      {windowWidth > 768 ? (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="box-sector"
        >
          <motion.div variants={sliderUp} className="box-show">
            <motion.div variants={sliderUp2} className="box-main-text suk-bold">
              Beliefs and values.
            </motion.div>
            <motion.div
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              Our core belief in business is honesty towards the residents we
              serve.
            </motion.div>
          </motion.div>
          <motion.div variants={sliderUp} className="box-show">
            <motion.div variants={sliderUp2} className="box-main-text suk-bold">
              Goals for the future.
            </motion.div>
            <motion.div
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              We dream of a business that fosters sustainable home construction,
              and we are committed to growing and developing this business
              further.
            </motion.div>
          </motion.div>
          <motion.div variants={sliderUp} className="box-show">
            <motion.div variants={sliderUp2} className="box-main-text suk-bold">
              The differences.
            </motion.div>
            <motion.div
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              Our key strength in the services we provide to residents is our
              collaborative approach to preserving the environment together.
            </motion.div>
          </motion.div>
        </motion.div>
      ) : (
        <motion.div className="box-sector">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            variants={sliderUp}
            className="box-show"
          >
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp2}
              className="box-main-text suk-bold"
            >
              Beliefs and values.
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              Our core belief in business is honesty towards the residents we
              serve.
            </motion.div>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            variants={sliderUp}
            className="box-show"
          >
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp2}
              className="box-main-text suk-bold"
            >
              Goals for the future.
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              We dream of a business that fosters sustainable home construction,
              and we are committed to growing and developing this business
              further.
            </motion.div>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            variants={sliderUp}
            className="box-show"
          >
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp2}
              className="box-main-text suk-bold"
            >
              The differences.
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              variants={sliderUp3}
              className="box-detail-text suk-thin"
            >
              Our key strength in the services we provide to residents is our
              collaborative approach to preserving the environment together.
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}
const LetterAnimation = ({ text }) => {
  return (
    <div style={{ display: "flex" }}>
      {text.split("").map((letter, index) => (
        <motion.span
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          key={index}
          // initial={{ opacity: 0, y: -20 }}
          // animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          {letter}
        </motion.span>
      ))}
    </div>
  );
};
export default About;
