import ".././css/demo.css";
import { motion, Variants } from "framer-motion";

const sliderRight = {
  offscreen: {
    x: 300,
  },
  onscreen: {
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 0.8,
    },
  },
};
const sliderLeft = {
  offscreen: {
    x: -300,
  },
  onscreen: {
    x: 0,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 0.8,
    },
  },
};

// function Card({ emoji, hueA, hueB }) {
//   const background = `linear-gradient(306deg, ${hue(hueA)}, ${hue(hueB)})`;

//   return (
//     <motion.div
//       className="card-container"
//       initial="offscreen"
//       whileInView="onscreen"
//       viewport={{ once: true, amount: 0.8 }}
//     >
//       <div className="splash" style={{ background }} />
//       <motion.div className="card" variants={cardVariants}>
//         {emoji}
//       </motion.div>
//     </motion.div>
//   );
// }

export default function DemoTest() {
  return (
    <div className="">
      <div className="row">
        <div className="col-6">
          <motion.div
            className=""
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div className="card" variants={sliderLeft}>
              ที่่1
            </motion.div>
          </motion.div>
        </div>
        <div className="col-6">
          <motion.div
            className=""
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div className="card" variants={sliderRight}>
              ที่่2
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
