import "./../../css/project/show-list.css";
import { motion, Variants } from "framer-motion";
import React, { useState, useEffect } from "react";
import SliderPlot from "./showPlot/slider-plot.js";
import { useTranslation } from "react-i18next";
import Plot1 from "./../../image/project/showPlot/plot1.png";
import Plot2 from "./../../image/project/showPlot/plot2.png";
function ShowPlot({ innerWidth }) {
  const { i18n, t } = useTranslation();
  const [showText, setShowText] = useState(true);
  useEffect(() => {
    setShowText(false);
    setTimeout(() => {
      setShowText(true);
    }, 500);
  }, [i18n.language]);
  const image = [
    {
      imgURL: Plot1,
      imgAlt: "img-1",
    },
    {
      imgURL: Plot2,
      imgAlt: "img-2",
    },
  ];
  let circleAnimation = [];
  let textAnimation = [];
  let line1Animation = [];
  let line2Animation = [];
  let textlineAnimation = [];
  let timeIncrease = 0.5;
  let textDetail = [
    "1565.73",
    "1779.53",
    "1601.48",
    "1328.15",
    "1503.94",
    "1744.46",
    "1622.72",
    "1785.43",
    "1654.46",
    "1562.77",
    "1982.04",
    "2712.72",
  ];
  for (let i = 0; i < 12; i++) {
    circleAnimation[i] = {
      offscreen: {
        opacity: 0,
        scale: 0,
      },
      onscreen: {
        opacity: 1,
        scale: 1,
        transition: {
          type: "fade",
          duration: 0.3,
          delay: 0 + i * timeIncrease,
        },
      },
    };
    textAnimation[i] = {
      offscreen: {
        opacity: 0,
        y: "0.5vw",
      },
      onscreen: {
        opacity: 1,
        y: 0,
        transition: {
          type: "fade",
          duration: 0.3,
          delay: 0.3 + i * timeIncrease,
        },
      },
    };
    line1Animation[i] = {
      offscreen: {
        // opacity: 0,
        rotate: "skewX(25deg)",
        height: 0,
        display: "none",
      },
      onscreen: {
        // opacity: 1,
        height: innerWidth > 768 ? "2vw" : innerWidth > 430 ? "2.5vw" : "4.5vw",
        rotate: "skewX(25deg)",
        display: "block",
        transition: {
          type: "fade",
          duration: 0.3,
          delay: 0.4 + i * timeIncrease,
        },
      },
    };
    line2Animation[i] = {
      offscreen: {
        width: 0,
        display: "none",
      },
      onscreen: {
        display: "block",
        width:
          innerWidth > 768
            ? i18n.language === "fr"
              ? "21.5vw"
              : i18n.language === "de"
              ? "20vw"
              : "17vw"
            : innerWidth > 430
            ? i18n.language === "fr"
              ? "27vw"
              : i18n.language === "de"
              ? "25vw"
              : "17vw"
            : i18n.language === "fr"
            ? "43.5vw"
            : i18n.language === "de"
            ? "40.5vw"
            : "17vw",
        transition: {
          type: "spring",
          duration: 0.8,
          delay: 0.7 + i * timeIncrease,
        },
      },
    };
    textlineAnimation[i] = {
      offscreen: {
        x: i18n.language === "de" || i18n.language === "fr" ? "-21vw" : "-15vw",
      },
      onscreen: {
        x: 0,
        transition: {
          type: "spring",
          duration: 0.7,
          delay: 1.2 + i * timeIncrease,
        },
      },
    };
  }

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      className="row plot-container"
    >
      <div className="col-9 plot-slider">
        <SliderPlot image={image}></SliderPlot>
      </div>
      <div
        className={
          "col-3 text-show position-relative " +
          (i18n.language === "fr" ? "text-show-fr" : "") +
          " " +
          (i18n.language === "de" ? "text-show-de" : "")
        }
      >
        {showText ? (
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={"plot-text "}
          >
            {textDetail.map((doc, key) => {
              return (
                <div key={key} className="plot-line">
                  <motion.div
                    variants={line1Animation[key]}
                    className="plot-line-1"
                  ></motion.div>
                  <motion.div
                    variants={line2Animation[key]}
                    className="plot-line-2"
                  ></motion.div>
                  <div className="d-flex plot-flex">
                    <motion.div
                      variants={circleAnimation[key]}
                      className="circle-plot"
                    >
                      <motion.div
                        variants={textAnimation[key]}
                        className="title-text suk-bold"
                      >
                        {key + 1}
                      </motion.div>
                    </motion.div>
                    <div className="show-detial">
                      <motion.div
                        variants={textlineAnimation[key]}
                        className="detail-text suk-semibold "
                      >
                        {t("project_land_area")} {doc} m<sup>2</sup>
                      </motion.div>
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        ) : null}
      </div>
    </motion.div>
  );
}
export default ShowPlot;
