import "./../css/project.css";
import Slider from "./../component/project/slider.js";
import Slider1 from "./../image/slider/1.webp";
import Slider2 from "./../image/slider/2.webp";
import Slider3 from "./../image/slider/3.webp";
import Slider4 from "./../image/slider/4.webp";
import Slider5 from "./../image/slider/5.webp";
import Slider6 from "./../image/slider/6.webp";
import Slider7 from "./../image/slider/7.webp";
import Slider8 from "./../image/slider/8.webp";
import Slider9 from "./../image/slider/9.webp";
import Slider10 from "./../image/slider/10.webp";
import Slider11 from "./../image/slider/11.webp";
import Slider12 from "./../image/slider/12.webp";
import Room from "./../image/project/room.webp";
import ShowPrice from "./../image/project/show-price.webp";
import { motion, Variants } from "framer-motion";
import ShowAround from "../component/project/show-around.js";
import ShowComponent from "../component/project/show-component.js";
import ShowDemo from "../component/project/show-demo.js";
import ShowList from "../component/project/show-list.js";
import ShowPlot from "../component/project/show-plot.js";
import axios from "axios";
// import ShowSun from "../component/project/show-sun.js";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
function Project() {
  const form = useRef();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");
  const [canclick, setCanclick] = useState(1);
  const [validEmail, setValidEmail] = useState(0);
  const [innerWidth, setWindowWidth] = useState(window.innerWidth);
  const { i18n, t } = useTranslation();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const handleJoin = (e) => {
    setCanclick(0);
    e.preventDefault();
    if (firstname !== "" && lastname !== "" && email !== "" && detail !== "") {
      if (canclick) {
        axios
          .post("https://costcontrol.mirana-th.com/api/mail/send", {
            firstname: firstname,
            lastname: lastname,
            email: email,
            detail: detail,
          })
          .then(
            (result) => {
              setCanclick(1);
              setFirstname("");
              setLastname("");
              setEmail("");
              setDetail("");
              console.log("done");
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } else {
      if (email === "") {
        setValidEmail(1);
      } else {
        setValidEmail(0);
      }
    }
  };
  const images = [
    {
      imgURL: Slider1,
      imgAlt: "img-1",
    },
    {
      imgURL: Slider2,
      imgAlt: "img-2",
    },
    {
      imgURL: Slider3,
      imgAlt: "img-3",
    },
    {
      imgURL: Slider4,
      imgAlt: "img-4",
    },
    {
      imgURL: Slider5,
      imgAlt: "img-5",
    },
    {
      imgURL: Slider6,
      imgAlt: "img-6",
    },
    {
      imgURL: Slider7,
      imgAlt: "img-7",
    },
    {
      imgURL: Slider8,
      imgAlt: "img-8",
    },
    {
      imgURL: Slider9,
      imgAlt: "img-9",
    },
    {
      imgURL: Slider10,
      imgAlt: "img-10",
    },
    {
      imgURL: Slider11,
      imgAlt: "img-11",
    },
    {
      imgURL: Slider12,
      imgAlt: "img-12",
    },
  ];
  const sliderUp = {
    offscreen: {
      opacity: 0,
      y: 50,
      x: innerWidth > 430 ? 0 : "-50%",
    },
    onscreen: {
      opacity: 1,
      y: 0,
      x: innerWidth > 430 ? 0 : "-50%",
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };
  const sliderUp2 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 0.3,
      },
    },
  };
  const sliderUp3 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 0.6,
      },
    },
  };
  const sliderUp4 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 0.9,
      },
    },
  };
  const sliderUp5 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.2,
      },
    },
  };
  const sliderUp6 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.5,
      },
    },
  };
  const sliderUp7 = {
    offscreen: {
      opacity: 0,
      y: 50,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "fade",
        duration: 0.5,
        delay: 1.8,
      },
    },
  };
  const sliderLeft = {
    offscreen: {
      opacity: 0,
      width: 0,
    },
    onscreen: {
      opacity: 1,
      width: "100%",
      transition: {
        type: "fade",
        duration: 0.5,
      },
    },
  };

  const showBox = {
    offscreen: {
      opacity: 0,
      width: 0,
    },
    onscreen: {
      opacity: 1,
      width: "23vw",
      transition: {
        type: "fade",
        duration: 0.4,
      },
    },
  };
  const showBox2 = {
    offscreen: {
      opacity: 0,
      width: 0,
    },
    onscreen: {
      opacity: 1,
      width: "23vw",
      transition: {
        type: "fade",
        duration: 0.4,
        delay: 0.4,
      },
    },
  };
  return (
    <div className="project">
      {/* <div className="box-color-background"></div> */}
      <div className="div-text">
        <div className="main-title show-project-div">
          <motion.div
            initial={{ x: -350, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                type: "fade",
                duration: 0.5,
              },
            }}
            className="suk-bold"
          >
            MIN-
          </motion.div>
          <motion.div
            initial={{ y: -150, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                type: "fade",
                duration: 0.5,
              },
            }}
            className="suk-bold"
          >
            ERGIE
          </motion.div>
          <motion.div
            initial={{ x: 350, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                type: "fade",
                duration: 0.5,
              },
            }}
            className="suk-bold"
          >
            {" "}
            VILLAGE
          </motion.div>
          <motion.div
            initial={{ width: "0vw", opacity: 0 }}
            animate={{
              width: "31vw",
              opacity: 1,
              transition: {
                type: "fade",
                duration: 0.4,
                delay: 0.5,
              },
            }}
            className="line-title"
          ></motion.div>
        </div>
        <motion.div
          initial={{ y: -40, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              bounce: 0.5,
              duration: 1.2,
              delay: 1,
            },
          }}
          className="sub-title suk-semibold"
        >
          PATTAYA
        </motion.div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="slider">
          <Slider images={images}></Slider>
        </div>
      </div>
      <div className="list">
        <ShowList image={ShowPrice} />
      </div>
      {innerWidth > 768 ? (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          className="room"
        >
          <div className="row w-100 position-relative">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="sun"
              variants={showBox}
            >
              <motion.div variants={sliderUp3} className="room-title suk-bold">
                {t("project_solar_cells")}
              </motion.div>
              <motion.div
                variants={sliderUp4}
                className="room-sub-title suk-medium"
              >
                {t("project_solar_cells_data")}
              </motion.div>
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="text-sector-1"
              variants={showBox}
            >
              <motion.div variants={sliderUp5} className="room-title suk-bold">
                {t("project_ventilation")}
              </motion.div>
              <motion.div
                variants={sliderUp6}
                className="room-sub-title suk-medium"
              >
                {t("project_ventilation_data")}
              </motion.div>
            </motion.div>
            <motion.div className="room-1">
              <motion.img
                variants={sliderUp}
                src={Room}
                alt="room"
              ></motion.img>
            </motion.div>
            <motion.div className="text-sector-2" variants={showBox2}>
              <motion.div variants={sliderUp4} className="room-title suk-bold">
                {t("project_regional")}
              </motion.div>
              <motion.div
                variants={sliderUp7}
                className="room-sub-title suk-medium"
              >
                {t("project_regional_data")}
              </motion.div>
            </motion.div>
            <motion.div className="text-sector-3 suk-semi" variants={sliderUp5}>
              {t("project_floor_plan")}
            </motion.div>
          </div>
        </motion.div>
      ) : (
        <motion.div className="room">
          <div className="row w-100 position-relative">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="text-sector-1"
              variants={showBox}
            >
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={sliderUp3}
                className={
                  "room-title suk-bold " +
                  (i18n.language === "de" ? "room-title-de" : "")
                }
              >
                {t("project_ventilation")}
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={sliderUp4}
                className="room-sub-title suk-medium"
              >
                {t("project_ventilation_data")}
              </motion.div>
            </motion.div>
            <motion.div className="room-1">
              <motion.img
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={sliderUp}
                src={Room}
                alt="room"
              ></motion.img>
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="text-sector-3 suk-bold"
              variants={sliderUp4}
            >
              {t("project_floor_plan")}
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="sun"
              variants={showBox}
            >
              <motion.div variants={sliderUp4} className="room-title suk-bold">
                {t("project_solar_cells")}
              </motion.div>
              <motion.div
                variants={sliderUp5}
                className="room-sub-title suk-medium"
              >
                {t("project_solar_cells_data")}
              </motion.div>
            </motion.div>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true }}
              className="text-sector-2"
              variants={showBox2}
            >
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={sliderUp6}
                className="room-title suk-bold"
              >
                {t("project_regional")}
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                variants={sliderUp7}
                className="room-sub-title suk-medium"
              >
                {t("project_regional_data")}
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      )}
      {/* <div className="sun-show">
        <ShowSun />
      </div> */}
      <div className="around">
        <ShowAround />
      </div>
      <div className="component">
        <ShowComponent innerWidth={innerWidth} />
      </div>
      <div className="plot">
        <ShowPlot innerWidth={innerWidth} />
      </div>
      <div className="demo">
        <ShowDemo />
      </div>

      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        className="map"
      >
        <motion.div
          variants={sliderLeft}
          className="map-background"
          style={{ height: "100%" }}
        ></motion.div>
        <div className="show-row">
          <div className="text-show">
            <motion.div variants={sliderUp3} className="map-our-text suk-bold">
              {t("project_our_location")}
            </motion.div>
            <motion.div variants={sliderUp4} className="map-text1 suk-bold">
              {t("project_easy_access")}
            </motion.div>
            <motion.div variants={sliderUp5} className="map-text2 suk-bold">
              {t("project_conveniently")}
            </motion.div>
            <motion.div
              variants={sliderUp6}
              className={
                "map-text3 suk-medium " +
                (i18n.language === "fr" ? "map-text3-fr" : "") +
                " " +
                (i18n.language === "de" ? "map-text3-de" : "")
              }
            >
              {t("project_our_data")}
            </motion.div>
            <motion.div
              variants={sliderUp6}
              className={
                "map-text4 suk-medium " +
                (i18n.language === "fr" ? "map-text4-fr" : "") +
                " " +
                (i18n.language === "de" ? "map-text4-de" : "")
              }
            >
              {t("project_our_data_2")}
            </motion.div>
          </div>
          {innerWidth < 431 ? (
            <motion.div
              // variants={{
              //   offscreen: {
              //     opacity: 0,
              //     scale: 0,
              //   },
              //   onscreen: {
              //     opacity: 1,
              //     scale: 1,
              //     transition: {
              //       type: "fade",
              //       duration: 0.5,
              //       delay: 0.4,
              //     },
              //   },
              // }}
              className="show-map"
            >
              <iframe
                title="myLocation"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3890.3555961375437!2d100.98945287575401!3d12.820283018176328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDQ5JzEzLjAiTiAxMDDCsDU5JzMxLjMiRQ!5e0!3m2!1sth!2sth!4v1713431022930!5m2!1sth!2sth"
                width="600"
                height="450"
                className="i-map"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
              title="myLocation"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15108.548188137267!2d99.0018432!3d18.79204485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3ab48228ba85%3A0x427e1e87368ddbe0!2z4LmC4Lij4LiH4Lie4Lii4Liy4Lia4Liy4Lil4LmB4Lih4LiE4LiE4Lit4Lij4LmM4Lih4Li04LiE!5e0!3m2!1sth!2sth!4v1707137753335!5m2!1sth!2sth"
              width="600"
              height="450"
              className="i-map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            </motion.div>
          ) : (
            <motion.div
              variants={{
                offscreen: {
                  opacity: 0,
                  scale: 0,
                },
                onscreen: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: "fade",
                    duration: 0.5,
                    delay: 0.4,
                  },
                },
              }}
              className="show-map"
            >
              <iframe
                title="myLocation"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3890.3555961375437!2d100.98945287575401!3d12.820283018176328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDQ5JzEzLjAiTiAxMDDCsDU5JzMxLjMiRQ!5e0!3m2!1sth!2sth!4v1713431022930!5m2!1sth!2sth"
                width="600"
                height="450"
                className="i-map"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <iframe
              title="myLocation"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15108.548188137267!2d99.0018432!3d18.79204485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3ab48228ba85%3A0x427e1e87368ddbe0!2z4LmC4Lij4LiH4Lie4Lii4Liy4Lia4Liy4Lil4LmB4Lih4LiE4LiE4Lit4Lij4LmM4Lih4Li04LiE!5e0!3m2!1sth!2sth!4v1707137753335!5m2!1sth!2sth"
              width="600"
              height="450"
              className="i-map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            </motion.div>
          )}
        </div>
      </motion.div>
      
      <div className="show-email-send">
        <form ref={form} onSubmit={handleJoin}>
          <div className="row">
            <div
              className="col-6"
              style={{ marginBottom: "5px", paddingRight: "10px" }}
            >
              <input
                type="text"
                name="first_name"
                style={{ backgroundColor: "white" }}
                className="form-control"
                placeholder="First name"
                onChange={(data) => {
                  setFirstname(data.nativeEvent.target.value);
                }}
                value={firstname}
              />
            </div>
            <div className="col-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="last_name"
                style={{ backgroundColor: "white" }}
                className="form-control"
                placeholder="Last name"
                onChange={(data) => {
                  setLastname(data.nativeEvent.target.value);
                }}
                value={lastname}
              />
            </div>
            <div className="col-12" style={{ marginBottom: "5px" }}>
              <input
                type="email"
                name="email"
                style={{ backgroundColor: "white" }}
                className={"form-control " + (validEmail ? "is-invalid" : "")}
                placeholder="Email"
                onChange={(data) => {
                  setEmail(data.nativeEvent.target.value);
                }}
                value={email}
              />
            </div>
            <div className="col-12" style={{ marginBottom: "5px" }}>
              <label style={{ color: "#ced4da" }}>Detail</label>
              <textarea
                name="detail"
                row="10"
                className="form-control"
                placeholder="Message"
                style={{ backgroundColor: "white" }}
                onChange={(data) => {
                  setDetail(data.nativeEvent.target.value);
                }}
                value={detail}
              ></textarea>
            </div>
            <div className="col-12">
              <button
                type="submit"
                style={{ width: "100%" }}
                className="btn btn-j"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Project;
